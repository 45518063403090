import React from 'react';
import { withRouter } from "react-router-dom";

import close from '../../assets/icon/close_grey.svg';

const DetalleComboModal = (props) => {

    const handleClose = () => {
        document.querySelector(".modal-combo").classList.remove("show-modal");
    }

    return (
        <div className="modal modal-combo">
            <div className="modal-content modal-lg">       
                <div className="title">
                    <p>Detalle Combo</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div className="content full-content" >
                    <div className="list-items">
                        { props.detalleCombo ? props.detalleCombo.map((item, i) => (
                            <div className="item detalle-combo" style={{cursor:"default"}} key={i} >
                                <div className="card-img">
                                    <img className="img-product"
                                        src={'http://66.33.94.204/SyncEmart/Imgs/' + props.empresa.num_empresa + '/'+ item.codigo.toString().trim() + '.jpg'}
                                        onError={(e)=>{e.target.onerror = null; e.target.src=props.noimage}} >
                                    </img> 
                                </div>
                                <p className="cantidad-detalle-combo">
                                    {item.cantidad}
                                </p>
                                <p><b>{item.nombre}</b></p>
                            </div>
                        )) : null }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetalleComboModal);