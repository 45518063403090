import React, { useState, useEffect, useContext } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

//import { AppContext } from '../../context/AppState';

import Progress from '../util/Progress';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';

import noimage from '../../assets/img/noimage.png';

import descuento from '../../assets/img/descuento.png';

import DetalleComboModal from '../modal/DetalleComboModal';

const Producto = (props) => {

    const [carrito, setCarrito] = useState();
    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState();
    const [combos, setCombos] = useState([]);

    const [detalleCombo, setDetalleCombo] = useState([]);

    const [gruposDescuento, setGruposDescuento] = useState([]);

    // const [cantidad, setCantidad] = useState();
    let empresa = JSON.parse(localStorage.getItem('empresa'));

    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async (tipo) => {
        let url = (empresa.url ? (empresa.url.replace("http://sfa.grupochocolates.com/", "https://sfa.grupochocolates.com/") + (empresa.path ? empresa.path + "/" : "")) :
                    Constants.URL_BASE) +
                    Constants.WSPRODUCTO +
                    "?origen=" + empresa.codigo_empresa + 
                    "&cliente=" + empresa.sucursal.codigo;

        if(props.search){
            url += "&search=" + props.search;
        } else {
            if(props.categoria){
                url += "&ca=" + props.categoria.codigo;
            }
        }

        if(tipo == 1){
            if(productos){
                if(productos.now < productos.next){
                    url += "&page=" + productos.next;
                }
            }
        }

        axios.get(url)
        .then(res => {
            setLoader(false);
            let array = res.data;
            
            if(tipo == 1){
                if(productos){
                    if(productos.result){
                        let combine = productos.result.concat(array.result);
                        array.result = combine;
                    }
                }
            }
            
            setProductos(array);
        });

        props.handleCantidad();
    }

    const cargarMas = () => {
        loadProductos(1);
    }

    // AUMENTA LA CANTTIDAD
    const handleMas = item => {    
        if(carrito[item.codigo]){
            carrito[item.codigo]["cantidad"] += 1;
        }else{
            carrito[item.codigo] = {
                nombre: item.nombre,
                precio: parseFloat(item.precio),
                iva: parseInt(item.iva),
                cantidad: 1
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos(0);
    }

    const handleMenos = item => {
        if(carrito[item.codigo]){
            if(carrito[item.codigo]["cantidad"] == 1){
                delete carrito[item.codigo];
            }else{
                carrito[item.codigo]["cantidad"] -= 1;
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos(0);
    }

    const handleChangeCantidad = (item, valor) => {
        if(valor <= 0){
            delete carrito[item.codigo];
        }else{
            if(carrito[item.codigo]){
                carrito[item.codigo]["cantidad"] = parseInt(valor);
            }else{
                carrito[item.codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos();
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {

        setLoader(true);
        setCarrito(JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {});
        if(props.categoria){
            loadProductos(0);
        }
        cargarBanners(empresa.url, empresa.path, empresa.num_empresa)

        if (empresa.num_empresa == Constants.num_empresa_ramo){
            cargarCombos()
        }

    }, [props.categoria, props.search])

    const cargarCombos = () => {
        let url = Constants. URL_BASE + Constants.WSPRODUCTOSCOMBO + "?codigo=" + empresa.codigo_empresa + "&cliente=" + empresa.sucursal.codigo;

        axios.get(url)
        .then(res => {
            setCombos(res.data);
        });
    }

    const cargarBanners = (urlEmpresa, pathEmpresa, codEmpresa) => {
        let url = (urlEmpresa ? (urlEmpresa + (pathEmpresa ? pathEmpresa + "/" : "")) :
            Constants.URL_BASE) +
            Constants.WSBANNERS +
            "?numEmpresa=" + codEmpresa;

        axios.get(url)
        .then(res => {
            setLoader(false);
            localStorage.setItem("bannerImages", JSON.stringify(res.data));
        });
    }

    const getUrlBanner = () => {

        let listadoBanners = JSON.parse(localStorage.getItem("bannerImages"));
        let categoria = props.categoria;

        let urlImage = "";

        if (categoria){

            let dataImagen = listadoBanners.find(element => element.CodigoCategoria == categoria.codigo); 
            
            urlImage = (dataImagen ? dataImagen.Imagen : "")
        }
        
        return urlImage
    }

    const handleClickCombo = (combo) => {

        let infoDetalle

        combos.filter(o => o.codigopadre === combo.codigo).map((item, i) => {

            let url = (empresa.url ? (empresa.url.replace("http://sfa.grupochocolates.com/", "https://sfa.grupochocolates.com/") + (empresa.path ? empresa.path + "/" : "")) :
                Constants.URL_BASE) +
                Constants.WSPRODUCTO +
                "?origen=" + empresa.codigo_empresa + 
                "&cliente=" + empresa.sucursal.codigo + 
                "&search=" + item.codigohijo
            
            axios.get(url)
            .then(res => {

                let array = res.data;

                if (array.result.length > 0){
                    array.result[0].cantidad = item.cantidad
                }

                if(infoDetalle){
                    if(infoDetalle.result){
                        let combine = infoDetalle.result.concat(array.result);
                        array.result = combine;
                    }
                }
                
                infoDetalle = array;


                setDetalleCombo(infoDetalle);

            })
        })

        document.querySelector(".modal-combo").classList.toggle("show-modal");

    }

    // VISTA
    return (
        <div className="main">

            { Constants.empresas_banners.includes(empresa.num_empresa.toString()) ? (
                <div className="banner-ramo">
                    <img id="imagen-banner-ramo" src={getUrlBanner()}></img>
                </div>
            ) : null } 

            <div className={ Constants.empresas_banners.includes(empresa.num_empresa.toString()) ? 'pedido-list padding-banner' : 'pedido-list'}>
                { loader ? <Progress color={"#bf0811"} /> : (
                    <React.Fragment>  
                            {productos ? (
                                <React.Fragment>
                                    { props.tipo == 2 ? (
                                        <div className="product-list">
                                        { productos.total > 0 ? (
                                            <React.Fragment>
                                                {productos.result.map((item, i) => (
                                                    <div className="item" key={i}>

                                                        {/* {gruposDescuento.filter(o => o.codProducto === item.codigo).length > 0 ? 
                                                            (
                                                                <img src={descuento}></img>
                                                            ) : null
                                                        } */}

                                                        <p className="nombre">{item.nombre}</p>
                                                        <p className="codigo"><b>Codigo:</b> {item.codigo} - <b>EAN:</b> {item.ean}</p>
                                                        <p className="precio">
                                                            {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                                                            {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) }
                                                        </p>
                                                        <div className="action">
                                                            { carrito[item.codigo] ? (
                                                                <div className="controls">
                                                                    <button role="button" onClick={() => handleMenos(item)}>
                                                                        {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                                        <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                    <input type="number" 
                                                                        onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                        value={carrito[item.codigo].cantidad}/>
                                                                    <button role="button" onClick={() => handleMas(item)}>
                                                                        {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                                        <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </React.Fragment>
                                            ) : (
                                            <React.Fragment>
                                                <p>No se encontraron resultados</p>
                                            </React.Fragment>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="product-card">
                                            { productos.total > 0 ? (
                                                <React.Fragment>
                                                    {productos.result.map((item, i) => (
                                                        <div className="card" key={i}>
                                                            
                                                            {Constants.empresas_banners.includes(empresa.num_empresa.toString()) ? 
                                                                (
                                                                    JSON.parse(localStorage.getItem('gruposDescuento')).filter(o => o.codProducto === item.codigo).length > 0 ? 
                                                                        (
                                                                            <img src={descuento} width={50} className="img-descuento"></img>
                                                                        ) : null
                                                                ) : null
                                                            }

                                                            <div className="card-img">
                                                                <img className={ item.categoria == 'COMBOS' && empresa.num_empresa == Constants.num_empresa_ramo ? 'img-product cursor' : 'img-product'} 
                                                                    onClick={() => handleClickCombo(item)}
                                                                    src={'http://66.33.94.204/SyncEmart/Imgs/' + empresa.num_empresa + '/'+ item.codigo.trim() + '.jpg'}
                                                                    onError={(e)=>{e.target.onerror = null; e.target.src=noimage}} >
                                                                </img>  
                                                            </div>  
                                                            <div className="card-content">
                                                                <p className="nombre">{item.nombre}</p>
                                                                <p className="codigo"><b>Codigo:</b> {item.codigo}</p>
                                                                <p className="codigo"><b>EAN:</b> {item.ean}</p>
                                                                <p className="precio">
                                                                    {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                                                                    {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) }
                                                                </p>
                                                                <div className="action">
                                                                    { carrito[item.codigo] ? (
                                                                        <div className="controls">
                                                                            <button role="button" onClick={() => handleMenos(item)}>
                                                                                {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                                                <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                            </button>
                                                                            <input type="number" 
                                                                                onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                                value={carrito[item.codigo].cantidad}/>
                                                                            <button role="button" onClick={() => handleMas(item)}>
                                                                                {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                                                <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <React.Fragment>    
                                                                            <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                                           
                                                                        </React.Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <p>No se encontraron resultados</p>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}

                                    <div className="load-mas">
                                        {productos.now < productos.next ? (
                                            <button onClick={() => cargarMas()}>Cargar mas</button>
                                        ) : null }
                                    </div>
                                    
                                </React.Fragment>
                            ) : null}
                    </React.Fragment>
                )}
            </div>

            <DetalleComboModal detalleCombo={detalleCombo.result} empresa={empresa} noimage={noimage} />

        </div>

    )
}

export default Producto;